var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"name-trainingSystem"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên hệ đào tạo "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên hệ đào tạo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-trainingSystem","name":"nameTrainingSystem","placeholder":"Tên hệ đào tạo","state":_vm.getElementState(errors)},model:{value:(_vm.dataTrainingSystem.name),callback:function ($$v) {_vm.$set(_vm.dataTrainingSystem, "name", $$v)},expression:"dataTrainingSystem.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"trainingForms"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Loại hình đào tạo "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Loại hình đào tạo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optTrainingForms,"reduce":function (option) { return option.value; },"label":"text"},model:{value:(_vm.dataTrainingSystem.trainingFormId),callback:function ($$v) {_vm.$set(_vm.dataTrainingSystem, "trainingFormId", $$v)},expression:"dataTrainingSystem.trainingFormId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"yearNum"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số năm đào tạo "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Số năm đào tạo","rules":"required|positive_integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"yearNum","name":"yearNum","placeholder":"Số năm đào tạo","state":_vm.getElementState(errors),"type":"number"},model:{value:(_vm.dataTrainingSystem.yearNum),callback:function ($$v) {_vm.$set(_vm.dataTrainingSystem, "yearNum", $$v)},expression:"dataTrainingSystem.yearNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Số thứ tự","label-for":"status"}},[_c('b-form-input',{attrs:{"id":"order_no","type":"number"},model:{value:(_vm.dataTrainingSystem.orderNo),callback:function ($$v) {_vm.$set(_vm.dataTrainingSystem, "orderNo", $$v)},expression:"dataTrainingSystem.orderNo"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Trạng thái","label-for":"status"}},[_c('b-form-select',{attrs:{"id":"status","options":_vm.optionsStatus},model:{value:(_vm.dataTrainingSystem.status),callback:function ($$v) {_vm.$set(_vm.dataTrainingSystem, "status", $$v)},expression:"dataTrainingSystem.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-left"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":_vm.handleSaveData}},[_vm._v(" Lưu ")])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }