<template src="./index.html" />

<script>
import {
  BBreadcrumb,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/vi.js'
import 'vue2-datepicker/index.css'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import FormTrainingSystem from '@/views/admin/category/training-system/FormTrainingSystem.vue'

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'TrainingSystem',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    VueGoodTable,
    vSelect,
    FormTrainingSystem,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: true,
      user: getUser(),
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      items: [],
      itemsDisplay: [],
      editMode: false,
      fields: [
        {
          field: 'name',
          label: 'Hệ đào tạo',
          sortable: false,
        },
        {
          field: 'trainingFormName',
          label: 'Loại hình đào tạo',
          sortable: false,
        },
        {
          field: 'yearNum',
          label: 'Thời gian đào tạo',
          sortable: false,
        },
        {
          field: 'organizationName',
          label: 'Cơ quan chủ quản',
          sortable: false,
        },
        {
          field: 'status',
          label: 'Trạng thái',
          sortable: false,
        },
        {
          field: 'action',
          label: '',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      row_selected: null,
      dataTrainingSystem: {},

    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'trainingSystem/trainingSystems',
      total: 'trainingSystem/total',
      trainingForms: 'trainingSystem/trainingForms',
      organizations: 'trainingSystem/organizations',
      statuses: 'trainingSystem/statuses',
    }),
    rows() {
      return this.items.length
    },
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TRAININGSYSTEM)
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TRAININGSYSTEM)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.TRAININGSYSTEM)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getInit(),
        this.getData(this.serverParams.page, this.serverParams.perPage),
      ])
    } catch (e) {
      this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
    } finally {
      this.isLoading = false
    }

  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'trainingSystem/getTrainingSystems',
      getInit: 'trainingSystem/getInit',
      createTrainingSystem: 'trainingSystem/createTrainingSystem',
      deleteTrainingSystem: 'trainingSystem/deleteTrainingSystem',

    }),
    isSystemAdmin,
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.getData(this.serverParams.page, this.serverParams.perPage)
    },
    async onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      await this.getData(this.serverParams.page, this.serverParams.perPage)
    },
    async onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      await this.getData(this.serverParams.page, this.serverParams.perPage)
    },

    async onColumnFilter(params) {
      this.updateParams(params)
      await this.getData(this.serverParams.page, this.serverParams.perPage)
    },
    // load items is what brings back the rows from server

    editItem(item) {
      this.row_selected = item
      this.$bvModal.show('modal-trainingSystem')
    },

    async deleteItem(item) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: '',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Xóa',
          cancelButtonText: 'Hủy',
        })
        .then(async result => {
          if (result.isConfirmed) {
            const result = await this.deleteTrainingSystem(item.id)
            if (result) {
              await this.getData(this.serverParams.page, this.serverParams.perPage)
              this.showToast('Đã xóa thành công!', 'CheckIcon', 'success', '')
            } else {
              this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger', '')
            }
          }
        })
    },
    openModal() {
      this.dataTrainingSystem = {}
      this.$refs['modal-trainingSystem'].show()
    },

    editTrainingSystem(data) {
      this.dataTrainingSystem = data
      this.$refs['modal-trainingSystem'].show()
    },

    columnFilterFn() {
    },

    async saveDataTrainingSystem(data) {
      this.isLoading = true
      try {
        const dataSend = {
          id: data.id,
          name: data.name,
          orderNo: data.orderNo,
          status: data.status,
          trainingFormId: data.trainingFormId,
          organizationId: this.user.orgId,
          yearNum: data.yearNum,
        }
        const response = await this.createTrainingSystem(dataSend)
        if (response.isSuccessful) {
          this.showToast('Cập nhật thành công!', 'CheckIcon', 'success', '')
          await this.getData(this.serverParams.page, this.serverParams.perPage)
          this.$bvModal.hide('modal-trainingSystem')
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger', '')
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },

    getStatusName(id) {
      return this.statuses.find(status => status.id === id).name
    },

    async getData(page, perPage) {
      await this.getTrainingSystems({
        currentPage: page,
        itemsPerPage: perPage,
        status: 1,
      })
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>
<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
